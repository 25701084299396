import * as React from "react"
import { Link, graphql } from "gatsby"

import Layout from "../components/layout"
import Seo from "../components/seo"
import descriptionData from "../content/description.json"
import ProductContainer from "../components/productContainer"

const Trending = ({
  data: {
    allAirtable: { edges },
  },
}) => {
  return (
    <Layout>
      <Seo
        title={descriptionData.archive_party.pageTitle}
        description={descriptionData.archive_party.pageDescription}
      />

      <div className="descriptions-container container px-18 max-w-screen-xl mx-auto">
        <h1 class="px-4 text-2xl mt-5 mb-5 font-bold">
          {descriptionData.archive_party.heading}
        </h1>
        <p>{descriptionData.archive_party.description1}</p>
        <p className="px-4 text-l mt-5 mb-5 font-bold">
          Website data refreshes every Monday, Wednesday, Friday
        </p>
      </div>

      <div className="container max-w-screen-xl mx-auto">
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-all-dresses">
            <h4 className="text-xl font-semibold">Trending New Dresses</h4>
          </Link>
          <p>
            You'll find the biggest and tredning selection of women's dresses
            with at Collective dress. Every week, we add and refresh new styles
            in our party dress section, where you'll find mini, midi, and party
            dresses.
          </p>
        </div>
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-midi-dresses">
            <h4 className="text-xl font-semibold">Trending Midi Dresses</h4>
          </Link>
          <p>
            Whether it’s with sneakers on the weekend or pumps to work, a midi
            dress is perfect for almost any occasion.
          </p>
        </div>
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-maxi-dresses">
            <h4 className="text-xl font-semibold">Trending Maxi Dresses</h4>
          </Link>
          <p>
            Maxi dresses are a simple wardrobe staple that can easily take you
            from day to night, from casual to formal, and from Winter to Summer.
          </p>
        </div>
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-mini-dresses">
            <h4 className="text-xl font-semibold">Trending Mini Dresses</h4>
          </Link>
          <p>
            Everyone needs a go-to piece that’s as versatile as it is chic. And
            for most women, that ideal outfit comes in the form of a midi dress.
            This flattering and versatile staple can be worn year-round. Whether
            it’s with sneakers on the weekend or pumps to work, a midi dress is
            perfect for almost any occasion.
          </p>
        </div>
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-party-dresses">
            <h4 className="text-xl font-semibold">Trending Party Dresses</h4>
          </Link>
          <p>
            You'll find more than a few party dresses here at Collective with a
            low neckline, so you can show off your favourite assets. You'll also
            find party dresses with sleeves, so you can stay warm when the
            temperature drops.
          </p>
        </div>
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-formal-dresses">
            <h4 className="text-xl font-semibold">Trending Formal Dresses</h4>
          </Link>
          <p>
            Can't find the perfect formal dress? Here are some dresses that will
            make you shine at your next formal occasion!
          </p>
        </div>
        <div className="trending-category mt-4 mb-4 px-4">
          <Link className="product-link" to="/trending-bridesmaid-dresses">
            <h4 className="text-xl font-semibold">
              Trending Bridesmaid Dresses
            </h4>
          </Link>
          <p>
            Here are some dresses that will make you shine at your next formal
            occasion! We've compiled a list of styles and colors for every type:
            from eye-catching blocks to bold prints or soft hues. Whether it’s
            an important meeting with the boss, date night on Friday evening -
            these pieces have been created specifically so they can fit any
            event perfectly fine.
          </p>
        </div>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allAirtable(filter: { table: { eq: "archive_dress_party" } }) {
      edges {
        node {
          data {
            company
            product_image
            product_title
            product_price
            product_link
            product_description
          }
        }
      }
    }
  }
`

export const Head = () => (
  <Seo
    title={descriptionData.archive_party.pageTitle}
    description={descriptionData.archive_party.pageDescription}
  />
)

export default Trending
